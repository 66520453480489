import Image from 'next/image'
import React from 'react'

//Render a reusable circle with a WhatsApp icon.
const WppCircle = ({ className }) => {
  return (
    <button className={` ${className} w-[60px] h-[60px] rounded-full flex justify-center items-center  bg-[#25D366] duration-300 hover:scale-110`}>
      <Image
        src={'/brands/whatsapp.png'}
        width={35}
        height={100}
        priority={true}
        quality={100}
        alt='wppLogo'
      />
    </button>
  )
}

export default WppCircle;