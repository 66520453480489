'use client'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Image from 'next/image';
import { mobile_navLinks } from '@/utils';
import { usePathname, useRouter } from 'next/navigation';

//This component renders a drawer for navigation and authentication of the app, only in the mobile version.
// setOpenDrawer & openDrawer --- States to open and close the drawer
const MobileDrawer = ({ setOpenDrawer, openDrawer }) => {

  const router = useRouter();
  const path = usePathname()

  const goToLink = (href) => {
    setOpenDrawer(false)
    router.push(href)
  };

  const handleScroll = (e, target, offset = 0) => {
    setOpenDrawer(false)
    e.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
      <Box sx={{ width: 250 }} role="presentation" >
        <section className='w-full h-[100vh] flex flex-col justify-between items-center gap-y-4'>
          <article className='w-full h-[10%] flex justify-end items-center px-4'>
            <button onClick={() => setOpenDrawer(false)}>
              <Image
                src={'/icons/close.png'}
                quality={100}
                width={25}
                height={25}
                alt='Menu'
              />
            </button>
          </article>
          <article className='h-[80%] w-full flex flex-col justify-start items-start px-3 gap-y-4'>
            <div className='flex flex-col items-start w-full gap-y-2'>
              <button type='button' className='text-base font-semibold' onClick={() => goToLink('/')}> Home</button>
              <span className='w-full h-px bg-gray-400' ></span>
            </div>
            {
              path === '/' &&
              mobile_navLinks.map(item => (
                <div key={item.title} className='flex flex-col items-start w-full gap-y-2'>
                  <button type='button' onClick={(e) => { handleScroll(e, item.scroll, item.offset) }}>
                    <h1 className='text-base font-semibold'>{item.title}</h1>
                  </button>
                  <span className='w-full h-px bg-gray-400' ></span>
                </div>
              ))
            }
          </article>
          <Image
            src={'/marineBroker/logo.png'}
            width={120}
            height={100}
            alt='Logo marine brokers'
            className='w-32'
          />
        </section>
      </Box>
    </Drawer>
  );
}


export default MobileDrawer;