'use client'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

// Render an error feedback screen with a dynamic message and a return link.
// errorMessage --- string dinamyc error message
const Error = ({ errorMessage }) => {
  return (
    <main className='w-3/4 h-full flex flex-col items-center gap-y-2 py-40'>
      <Image
        src={'/assets/error.png'}
        width={400}
        height={100}
        alt='cloud error'
      />
      <h2 className=' text-4xl'>Ocurrio un error!</h2>
      <h4 className=' text-base font-medium text-gray-800'>{errorMessage}</h4>
      <h6 className=' text-base font-medium text-gray-800'>Comentanos el error en la seccion de <Link href={'/'} className='font-semibold text-red-600 underline'>contacto</Link></h6>
      <Link href={'/'} className='px-8 py-2 bg-red-600 text-white text-base font-semibold rounded-lg transition-all duration-300 hover:scale-110'>Volver</Link>
    </main>
  )
}

export default Error