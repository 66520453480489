'use client'
import Image from 'next/image';
import React, { useState } from 'react'
import Link from 'next/link';
import { MobileDrawer } from '@/app';
import { desktop_navLinks } from '@/utils';

const cssClasses = {
  navLinks: 'md:text-xs xl:text-base font-semibold rounded hover:scale-110 hover:text-primary transition-all duration-300'
}

//This component renders the app nav bar. 
//In desktop version, the component have navigation and authentication.
//In mobile versions, the component have authentication and a button to render a drawer

const NavBar = () => {

  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);

  // The canSeeAuth state is used to prevent the user from seeing links they shouldn't see at certain times. For example, if they are in the brokers section, they shouldn't see the contact, about us, etc., buttons.
  const handleScroll = (e, target, offset = 0) => {
    e.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {/* Desktop */}
      <main className='w-full h-20 hidden md:flex justify-between items-center z-20 px-10 bg-white border-b-2'>
        <Image
          src={'/marineBroker/mbVLogo.png'}
          width={110}
          height={100}
          quality={100}
          className='w-28'
          alt='Logo'
        />
        <nav className='flex justify-around items-center gap-x-6 '>
          <Link href='/' className={cssClasses.navLinks}>Home</Link>
          {
            desktop_navLinks.map(item => (
              <button key={item.title} className={cssClasses.navLinks} onClick={(e) => { handleScroll(e, item.scroll, item.offset) }}>{item.title}</button>
            ))
          }
        </nav>
      </main>

      {/* mobile */}
      <main className='w-full h-auto flex md:hidden flex-col z-20'>
        <section className='w-full h-16 flex items-center justify-between px-5 bg-white border-b'>
          <Image
            src={'/marineBroker/mbVLogo.png'}
            width={80}
            height={100}
            quality={100}
            alt='Logo'
          />
          <button className='block md:hidden' onClick={() => setOpenMobileDrawer(true)}>
            <Image
              src={'/icons/menu.png'}
              alt='menu'
              width={25}
              height={100}
            />
          </button>
        </section>
 
        {openMobileDrawer && <MobileDrawer setOpenDrawer={setOpenMobileDrawer} openDrawer={openMobileDrawer} />}
      </main>
    </>
  )
}

export default NavBar