import React from 'react'

const CommonButton = ({ action = () => { }, disabled = false, text = 'Ingrese un contenido para el boton', className = '', type = 'button' }) => {

  return (
    <button type={type} disabled={disabled} onClick={action} className={`transition-all duration-500 flex justify-center items-center text-sm py-3 px-5 font-semibold rounded-xl hover:scale-110  ${disabled && 'animate-pulse'} ${className}`}>
      {text}
    </button>
  )
}

export default CommonButton