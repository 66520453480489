import { ModalStyles } from '@/utils';
import { Box, Modal } from '@mui/material';
import React from 'react'


//Render an MUI modal with the dynamic children.
// open && setOpen - state to open and close the modal
const CommonModal = ({ open = false, setOpen, children }) => {

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyles}>
        {children}
      </Box>
    </Modal>
  )
}

export default CommonModal