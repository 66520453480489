'use client'
import React, { useState } from 'react';

const CommonInput = ({ values, onChange}) => {

  const { value, name, title, placeHolder, minlength, maxlength, type, required, titleColor } = values
  const [inputValue, setInputValue] = useState(value || '');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    let errorMessage = '';
    if (required && newValue.length === 0) {
      errorMessage = 'Este campo es obligatorio.';
    } else if (newValue.length < minlength) {
      errorMessage = `El mínimo de caracteres es ${minlength}.`;
    } else if (newValue.length > maxlength) {
      errorMessage = `El máximo de caracteres es ${maxlength}.`;
    }
    setError(errorMessage);
    onChange(e);
  };

  return (
    <label className="flex flex-col justify-center relative w-full h-full ">
      <span className={`flex text-xs font-semibold h-1/4 ${ titleColor || 'text-gray-700' }`}>{title}</span>
      <input
        onChange={handleChange}
        type={type}
        name={name}
        required={required}
        minLength={minlength}
        maxLength={maxlength}
        placeholder={placeHolder}
        value={inputValue}
        className={`w-auto h-11 flex-grow px-3 text-xs font-semibold rounded-sm text-gray-800 ring-1 ring-gray-200 ${error ? 'ring-red-500' : 'focus:ring-2 focus:ring-primary focus:outline-none'}`}
      />
      <span className='text-pink-600 text-xxs h-1/4'>{error}</span>
    </label>
  );
}

export default CommonInput;
